import React from "react";
import InfoGroup from "./info-group";
import Heading from './heading';

const InfoSection = ({ section }) => {
  const { heading, level, split, groups } = section;
  return (
    <div className="info-section">
      {heading && <Heading level={level} className="info-section__heading">{section.heading}</Heading>}
      {groups && (
        <ul
          className={`info-section__content ${
            split ? "info-section__content--split" : ""
          }`}
        >
          {groups.map((group, index) => (
            <InfoGroup group={group} key={index} />
          ))}
        </ul>
      )}
    </div>
  );
};
export default InfoSection;
