import React from "react";
import InfoSection from "./info-section";

const InfoPageTemplate = ({ title, sections }) => (
	<div className="info-wrapper">
		<h1>{title}</h1>
		<article>
			{sections.map((section, index) => <InfoSection section={section} key={index} />)}
		</article>
	</div>
);

export default InfoPageTemplate;