import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout";
import InfoPageTemplate from "../components/info-page-template";
import SEO from "../components/seo"

export const pageQuery = graphql`
  query InfoQuery($id: String!){
		markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
				sections {
					heading
					level
					split
					groups {
						label
						items {
							title
							description
						}
					}
				}
      }
    }
  }
`

const InfoPage = ({ data }) => {
	const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter } = markdownRemark
	const { title, sections } = frontmatter;

	return (
		<Layout className="page">
			<SEO
				title={title}
				description={`Info`}
			/>
			<InfoPageTemplate title={title} sections={sections} />
		</Layout>
	)
}

export default InfoPage