import React from "react";

const InfoGroup = ({ group }) => {
  const { label, items } = group;
  return (
    <li className="info-group">
      {label && <span className="info-group__label">{label}</span>}
      {items && items.map((item, index) => {
        const { title, description } = item;
        return (
          <ul className="info-group__items" key={index}>
            <li className="info-group__item">
              {title && (
                <span className="info-group__item--title">
                  {`${title}${description ? ", " : ""}`}
                </span>
              )}
              {description && title ? (
                <span className="info-group__item--description">
                  {description}
                </span>
              ) : (
                description
              )}
            </li>
          </ul>
        );
      })}
    </li>
  );
};

export default InfoGroup;
